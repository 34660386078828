<template>
	<footer class="bg-color-blue-dark py-8 sm:py-12 relative overflow-hidden">
		<section class="container xl:max-w-5xl mb-16">
			<h4 class="text-color-white font-bold text-sm mb-4">
				{{ locale === 'en' ? 'LEGAL' : 'RECHTLICHES' }}
			</h4>
			<nuxt-link
				to="https://hokify.at/imprint"
				class="text-color-white hover:underline inline-block"
				>{{ locale === 'en' ? 'Imprint' : 'Impressum' }}</nuxt-link
			>
		</section>
		<section class="mb-12 container xl:max-w-5xl flex flex-col lg:flex-row justify-between">
			<div class="z-10 mb-12 lg:mb-0 text-center sm:text-left">
				<h4 class="text-color-white font-bold text-sm mb-4 sm:mb-8">
					{{ locale === 'en' ? 'OUR PARTNERS' : 'UNSERE PARTNER' }}
				</h4>
				<div class="inline-block">
					<nuxt-link
						v-for="logo in getPartnerLogos"
						:key="logo.imgSource"
						:to="logo.imgLink"
						target="_blank"
						class="mx-8 mt-3 sm:mt-0 sm:mr-8 sm:ml-0 relative highlight inline-block"
					>
						<img
							:src="getAssetSrc(logo.imgSource)"
							:alt="logo.imgAlt"
							class="inline-block h-10 lg:h-8 align-middle white-filter"
							height="40"
						/>
					</nuxt-link>
				</div>
			</div>
			<div class="z-20">
				<h4 class="text-color-white text-center sm:text-left text-sm font-bold mb-6">
					{{ locale === 'en' ? 'FOLLOW US' : 'FOLGE UNS' }}
				</h4>
				<nav class="flex space-x-4 sm:space-x-6 justify-center">
					<nuxt-link
						to="https://twitter.com/hokify"
						target="_blank"
						class="highlight bg-color-white rounded-full h-12 w-12 flex items-center justify-center"
					>
						<HokIcon icon="icon-twitter-footer" aria-label="Twitter Logo" />
					</nuxt-link>
					<nuxt-link
						to="https://www.xing.com/companies/hokifymobilerecruiting"
						class="highlight bg-color-white rounded-full h-12 w-12 flex items-center justify-center"
					>
						<HokIcon color="main" pointer icon="icon-xing" aria-label="Xing Logo" />
					</nuxt-link>
					<nuxt-link
						to="https://www.linkedin.com/company/jobswipr"
						class="highlight bg-color-white rounded-full h-12 w-12 flex items-center justify-center"
					>
						<HokIcon color="main" pointer icon="icon-linkedin-2" aria-label="LinkedIn Logo" />
					</nuxt-link>
					<nuxt-link
						to="https://instagram.com/hokify"
						class="highlight bg-color-white rounded-full h-12 w-12 flex items-center justify-center"
					>
						<HokIcon icon="icon-instagram-footer" aria-label="Instagram Logo" />
					</nuxt-link>
					<nuxt-link
						to="https://facebook.com/hokify.at"
						class="highlight bg-color-white rounded-full h-12 w-12 flex items-center justify-center"
					>
						<HokIcon
							color="main"
							pointer
							:size="8"
							icon="icon-facebook"
							aria-label="Facebook Logo"
						/>
					</nuxt-link>
				</nav>
			</div>
		</section>
		<p class="relative z-10 mb-0 text-sm text-color-white text-center">
			hokify GmbH | part of the norika group
		</p>
		<img
			class="absolute bottom-0 left-0 z-0"
			src="~assets/svgs/hokify/hy_footer.svg"
			alt="Footer SVG Grafik"
		/>
	</footer>
</template>

<script lang="ts">
import { getPartnerLogos } from '@hokify/shared-components-nuxt3/lib/helpers/logos';
import { defineComponent } from 'vue';
import HokIcon from '@hokify/shared-components-nuxt3/lib/components/HokIcon.vue';

export default defineComponent({
	name: 'HokFooter',
	components: { HokIcon },
	data() {
		return {
			getPartnerLogos: getPartnerLogos('at', 'footer'),
			// locale: this.$store.state.locale
			locale: 'at'
		};
	},
	methods: {
		// works - but performance might be bad?
		// how can we access @shared-components without accessing node_modules?
		getAssetSrc(name: string) {
			const path = `../../node_modules/@hokify/shared-components-nuxt3/assets/svgs/logos/${name}`;
			const modules = import.meta.glob(
				'../../node_modules/@hokify/shared-components-nuxt3/assets/svgs/logos/**/*',
				{
					eager: true
				}
			) as Record<string, { default: string }>;

			if (modules[path]) {
				return modules[path].default;
			}
			return '';
		}
	}
});
</script>

<style scoped lang="scss">
.white-filter {
	filter: brightness(0) invert(1);
}
</style>
